import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const RowVideoStreamContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const WatchingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const VideoStyle = styled.div`
  width: -webkit-fill-available;
  height: 55vh;
  border-radius: 1rem;
  overflow: hidden;
  background: black;
`

export const RowVideoStream = styled.div`
  width: 278px;
  background-color: yellow;
  height: 251px;
  border-radius: 2rem;
  object-fit: cover;
`
export const TotalWatchingCount = styled.p`
color: white;
font-size: 15px;
`

export const AvtarHW = {
  bottom: '-12rem',
  left: '1rem'
}

export const cardHeaderStyle = {
  paddingBottom: 0
}

export const useVideoStyles = makeStyles(() => ({
  media: {
    height: 0
  },
  avatar: {
    borderRadius: 200,
    background: 'gray'
  },
  watching: {
    position: 'absolute',
    zIndex: 1,
    margin: '0.5rem',
    marginLeft: '1.5rem',
    alignItems: 'center'
  },
  space: {
    marginInlineEnd: 10
  },
  appLogo: {
    margin: 'auto',
    width: '50%'
  }
}))
