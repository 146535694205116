import { gql } from '@apollo/client'

const UserType = `
id
email
name
loginEnabled
facebookId
appleId
latitude
longitude
createdOn
connectedApple
isFollowing
isFollower
connectedFacebook
emailVerified
profileUrl
followerCount
followingCount`

const StreamType = `
  startedAt
  endedAt
  id
  title
  creator {
    ${UserType}
  }
  videochannel
  textchannel
  ongoing
  currentUsers
  cohostCount
  canCohost
  cohosts {
    ${UserType}
  }
  participants {
    startedAt
    endedAt
    role
    user {
      ${UserType}
    }
  }
  allowedVisitors {
    ${UserType}
  }
  requestedVisitors {
    ${UserType}
  }`

export const GET_STREAM_TOKEN = gql`
  query getStreamToken($streamid: ID!) {
    getStreamToken(streamid: $streamid) {
      success
      error
      videotoken
      stream {
        ${StreamType}
      }
      __typename
    }
  }
`
