import React from 'react'
import { createBrowserHistory } from 'history'
import './App.css'
import { Route, Router, Switch } from 'react-router-dom'
import JoinVideoStream from './components/JoinVideoStream'

const App = () => {
  return (
    <div className="App">
      <Router history={createBrowserHistory()}>
        <Switch>
          {/* Main Screen */}
          <div>
          <Route path="/:streamID" exact component={JoinVideoStream} />
          </div>
        </Switch>
      </Router>
    </div>
  )
}

export default App
