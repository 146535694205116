export enum Role {
    Audience = 'audience',
    Host = 'host'
}

export type AgoraAppConfig = {
    appID: string,
    channel: string,
    uid: number,
    token: string | null,
    key: string,
    secret: string,
}
export interface StreamAllDetails {
    stream: StreamType;
}
export interface StreamType {
    startedAt: string;
    endedAt: string;
    id: string;
    title?: string;
    creator?: AppUser;
    videochannel?: string;
    textchannel?: string;
    ongoing?: boolean;
    currentUsers: number;
    cohostCount: number;
    canCohost?: boolean;
    participants?: Participants[];
    cohosts?: AppUser[];
    allowedVisitors?: AppUser[];
    requestedVisitors: AppUser[];
}

export type AppUser = {
    createdOn: string;
    email: string;
    facebookId: string;
    id: string;
    name: string;
    loginEnabled: boolean;
    profileUrl: string;
    longitude: number;
    latitude: number;
    connectedApple: boolean;
    isFollowing: boolean;
    isFollower: boolean;
    emailVerified: boolean;
    connectedFacebook: boolean;
    followerCount: number;
    followingCount: number;
};
export interface Participants {
    __typename: 'Participants';
    startedAt: string;
    endedAt: string;
    user: AppUser;
    role: string;
}
