import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
  NormalizedCacheObject,
  Operation
} from '@apollo/client'
import { setContext } from '@apollo/link-context'
// import Constants from '../constants/Constants';
import { onError } from '@apollo/client/link/error'

// import {REFRESH_TOKEN} from '../graphql/Schema';
// import {isTokenExpire, makeid} from '../helper/Utils';

export const refreshToken = async () => {
  // const token = await AsyncStorage.getItem(Constants.STORAGE.ACCESS_TOKEN);
  // const fcmToken = await AsyncStorage.getItem(Constants.FCMTOKEN);

  // return await makeApolloClient()
  //   .mutate({
  //     mutation: REFRESH_TOKEN,
  //     variables: {
  //       token: token,
  //       DeviceType: {
  //         deviceId: fcmToken || makeid(10),
  //         platform: Platform.OS,
  //       },
  //     },
  //   })
  //   .then(res => {
  //     if (res.data.refreshToken.success === true) {
  //       AsyncStorage.setItem(
  //         Constants.STORAGE.ACCESS_TOKEN,
  //         res.data.refreshToken.token,
  //       );
  //       AsyncStorage.setItem(
  //         Constants.STORAGE.LAST_UPDATED_SESSION_TIME,
  //         new Date().valueOf().toString(),
  //       );
  //     }
  //   });
}

const client = () => {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL })

  const asyncAuthLink = setContext(async () => {
    // this is an async call, it will be done before each request
    // let token = await AsyncStorage.getItem(Constants.STORAGE.ACCESS_TOKEN);
    const token = '1234'
    // let lastTokenRefreshTime = await AsyncStorage.getItem(
    //   Constants.STORAGE.LAST_UPDATED_SESSION_TIME,
    // );
    // if (
    //   isTokenExpire(lastTokenRefreshTime) &&
    //   operationName !== 'refreshToken'
    // ) {
    //  await refreshToken();
    // }
    return {
      headers: {
        authorization: token
      }
    }
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    // Un comment if you want to pass token in Authorization!
    // add the authorization to the headers
    // operation.setContext(async () => {
    //   let token = await AsyncStorage.getItem(Constants.STORAGE.ACCESS_TOKEN);
    //   console.log('JWT token=>', token);
    //   return {
    //     headers: {
    //       Authorization: token ? `${token}` : '',
    //     },
    //   };
    // });
    return forward(operation).map(response => {
      if (process.env.REACT_APP_LOG_ENABLE === 'true') {
        printLog(operation, response)
      }
      return response
    })
  })

  const printLog = (operation: Operation, response: any) => {
    console.log(
      '%c' + operation?.operationName,
      'color:' +
        'yellow' +
        ';background-color: #444; padding: 3px 7px; margin-left: -7px;'
    )
    console.log(
      '%c' + 'Variables:',
      'color:' + 'blue' + '; padding: 3px 7px; margin-left: -7px;',
      operation?.variables
    )
    if (response?.data[`${operation?.operationName}`]?.success === true) {
      console.log(
        '%c' + 'Response',
        'color:' +
          'green' +
          ';background-color: #fff; padding: 3px 7px; margin-left: -7px;',
        response
      )
    } else {
      console.log(
        '%c' + 'Error: ' + response?.data[`${operation?.operationName}`]?.error,
        'color:' +
          'red' +
          ';background-color: #fff; padding: 3px 7px; margin-left: -7px;'
      )
    }
  }

  const graphqlError = onError((err: any) => {
    // console.log('Graphql ERROR--->', JSON.stringify(err));
    err.graphQLErrors?.map((err: Error) => {
      return console.log(
        '%c' + 'Error',
        'color:' +
          'red' +
          ';background-color: #fff; padding: 3px 7px; margin-left: -7px;',
        err.message
      )
    })
  })

  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([asyncAuthLink, authMiddleware, graphqlError, httpLink])
  })

  return apolloClient
}

export default client
