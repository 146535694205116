// @ts-ignore-file
import React, { useEffect, useMemo, useState } from 'react'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useQuery } from '@apollo/client'
import { GET_STREAM_TOKEN } from '../graphql/schema'
import { AgoraAppConfig, Role, StreamAllDetails } from '../types'
import AgoraRTC, { Client, Stream } from 'agora-rtc-sdk'
import { RouteComponentProps } from 'react-router'
import { TotalWatchingCount, useVideoStyles, WatchingContainer } from './JoinVideoStreamStyles'
import LiveLogo from '../assets/live.svg'
import Eye from '../assets/eye_light.svg'
import logo from '../assets/logo/main.svg'
import AppStore from '../assets/appStore.svg'
import PlayStore from '../assets/playStore.svg'

import '../App.css'
import { Link } from 'react-router-dom'

const option: AgoraAppConfig = {
  appID: process.env.REACT_APP_AGORA_APP_ID || '',
  channel: '',
  uid: 0,
  token: null,
  key: '',
  secret: ''
}

const StaticUserUrl = 'https://i.stack.imgur.com/l60Hf.png'

type Props = {
  localStream?: Stream;
};
type ComposedProps = Props &
  RouteComponentProps<{
    streamID: string;
  }>;

let rtcClient: Client
const JoinVideoStream = (ComposedProps: ComposedProps) => {
  const [multipleStreams, setMultipleStreams] = useState<Stream[]>([])
  const [streamDetails, setStreamDetails] = useState<StreamAllDetails>()
  // let match = useRouteMatch("/:streamID");
  const { streamID } = ComposedProps.match.params
  const [isAudienceJoin, setAudienceJoin] = useState<Boolean>(false)
  const [userUid, setUserUid] = useState<number>()
  const { data } = useQuery(GET_STREAM_TOKEN, {
    variables: { streamid: streamID }
  })

  useEffect(() => {
    if (data?.getStreamToken?.success) {
      // option.token = data.getStreamToken.videotoken;
      option.channel = streamID
      setAudienceJoin(true)
      joinChannel(Role.Audience)
      setStreamDetails(data.getStreamToken)
    }
  }, [data])

  useEffect(() => {
    return () => leaveEventAudience()
  }, [])

  const joinChannel = (role: Role) => {
    // Create a client
    rtcClient = AgoraRTC.createClient({ mode: 'live', codec: 'h264' })
    // Initialize the client
    rtcClient.init(
      option.appID,
      () => {
        rtcClient.setClientRole(role)
        rtcClient.join(
          option.token ? option.token : null,
          option.channel,
          option.uid ? +option.uid : null,
          'sample',
          (uid: number) => {
            onClientJoinSuccess(uid)
          },
          (error: string) => {
            alert(error)
          }
        )
        // Join a channel
      },
      (errMessage: string) => {
        console.error(errMessage)
      }
    )
  }

  const onClientJoinSuccess = (uid: number) => {
    setUserUid(uid)
    rtcClient.on('connection-state-change', (evt) => {
      console.log('audience', evt)
    })

    rtcClient.on('stream-published', (evt) => {
      console.log('stream-published', evt)
    })

    rtcClient.on('connected', () => {
      console.log('connected')
    })

    rtcClient.on('exception', (ex) => {
      console.log('exception', ex)
    })

    rtcClient.on('stream-added', (evt) => {
      console.log('stream-added========', evt)
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      if (id !== userUid) {
        rtcClient.subscribe(
          remoteStream,
          { audio: true, video: true },
          (err: string) => {
            console.log('stream subscribe failed', err)
          }
        )
      }
      console.log('stream-added remote-uid: ', id)
    })

    rtcClient.on('error', (error) => {
      console.log('error: ', error)
    })

    rtcClient.on('stream-removed', (evt) => {
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      console.log('stream-removed remote-uid: ', id)
    })

    rtcClient.on('stream-subscribed', (evt) => {
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      multipleStreams.push(remoteStream)
      setMultipleStreams([...multipleStreams])
      remoteStream.play(`remote_video_${multipleStreams.length - 1}`, { muted: false, fit: 'cover' })
      console.log('stream-subscribed remote-uid: ', id)
      console.log('multipleStreams added', multipleStreams.length)
    })

    rtcClient.on('peer-leave', (evt) => {
      const uid = evt.uid
      const reason = evt.reason
      const updatedMultipleStreams = multipleStreams.filter(
        (stream) => stream.getId() !== uid
      )
      setMultipleStreams([...updatedMultipleStreams])
      console.log('remote user left ', uid, 'reason: ', reason)
    })
  }

  const leaveEventAudience = () => {
    if (rtcClient) {
      rtcClient.leave(
        () => {
          console.log('client leaves channel')
          // ……
        },
        (err) => {
          console.log('client leave failed ', err)
          // error handling
        }
      )
    }
  }

  const totalWatchingCount = useMemo(() => {
    const count = streamDetails ? (streamDetails.stream.currentUsers - streamDetails.stream.cohostCount) : 0
    return isNaN(count) ? 0 : count
  }, [streamDetails?.stream?.currentUsers, streamDetails?.stream?.cohostCount])

  const classes = useVideoStyles()
  return (
    <div className={'root'}>
      <img src={logo} className='logo' />
      {isAudienceJoin && streamDetails && (<>
        <div className={'card'}>
          <CardHeader
            avatar={
              <Avatar
                aria-label="user"
                className={classes.avatar}
                src={streamDetails.stream?.creator?.profileUrl || StaticUserUrl}
              />
            }
            className='cardHeaderStyle'
            action={
              <IconButton aria-label="settings">
                <MoreHorizIcon />
              </IconButton>
            }
            title={streamDetails.stream?.creator?.name}
            subheader={streamDetails.stream?.title}
          />
          <CardContent className={'cardContentStyle'}>
            <WatchingContainer className={classes.watching}>
              <img src={LiveLogo} className={classes.space} />
              <img src={Eye} className={classes.space} />
              <TotalWatchingCount>
                {totalWatchingCount}
              </TotalWatchingCount>
            </WatchingContainer>
            <div className='VideoStyle'
              id={'remote_video_0'}>
            </div>
            {/* <RowVideoStreamContainer>
            {multipleStreams.slice(1, multipleStreams.length).map((item: any, index: number) => {
              const newIndex = index + 1
              return <RowVideoStream key={index.toString()}
                id={`remote_video_${newIndex}`}>
                <Avatar
                  alt="Remy Sharp"
                  src={AVATAR_IMG}
                  style={AvtarHW}
                />
              </RowVideoStream>
            })}
          </RowVideoStreamContainer> */}
          </CardContent>
        </div>
        <div className='downloadContainer'>
          <p className='download'>Download to Participate</p>
          <button className="btn" onClick={() => {
            window.open('https://apps.apple.com/us/app/streamplex/id1550979960')
          }}>
            <img src={AppStore} className='img' />
            <span className='btnText'>AppStore</span>
          </button>
          <button className="btn" onClick={() => {
            window.open('https://play.google.com/store/apps/details?id=com.streamplex.app')
          }}>
            <img src={PlayStore} className='img' />
            <span className='btnText'>Play Store</span>
          </button>
        </div>
        <div className={'privacyContainer'}>
          <Link className='link' to={{ pathname: 'https://streamplex.com/privacy-policy' }} target="_blank" >
            <span className='Privacy'>Privacy policy</span>
          </Link>
          <Link className='link' to={{ pathname: 'https://streamplex.com/terms-and-conditions' }} target="_blank" >
            <span className='Privacy'>Terms & Condition</span>
          </Link>
        </div>
      </>
      )}
    </div>
  )
}

export default JoinVideoStream
